import { HttpClient } from '@angular/common/http';
import { Injectable, signal } from '@angular/core';

import { environement } from '@app/environments/environment';
import { User } from '@app/models/user/user.model';
import { Observable, tap } from 'rxjs';
import { BaseService } from '@services/base.service';
import { MessageService } from 'primeng/api';

@Injectable({
  providedIn: 'root',
})
export class UserService extends BaseService {
  signalUser = signal<User | null>(null);

  constructor(
    protected override http: HttpClient,
    protected override messageService: MessageService,
  ) {
    super(http, messageService);
  }

  getUser = (providerId: string): Observable<User> => {
    return this.executeRequest(
      this.http.get<User>(
        `${environement.BACKEND_URL}/user/search/findByProviderId?providerId=${providerId}`,
      ),
    ).pipe(
      tap((user) => {
        this.signalUser.set(user);
      }),
    );
  };
}
